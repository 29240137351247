import { Component } from '@angular/core';


@Component({
    selector: 'app-warning-hex',
    imports: [],
    templateUrl: './warning-hex.component.html',
    styles: []
})
export class WarningHexComponent {}
